import axios from 'axios';
import React from 'react';
import Banner from '../../../components/property/Banner';
import Properties from '../../../components/property/Properties';
import { Layout } from '../../components/common';

const PropertiesPage = () => {
  const apiBaseUrl = process.env.GATSBY_API_BASE_URL;
  const [properties, setProperties] = React.useState();
  React.useEffect(() => {
    axios
      .get(`${apiBaseUrl}/v1/marketing-site/property-management/properties`)
      .then((response) => {
        setProperties(response.data.items);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  console.log('prop', properties);

  return (
    <div>
      <Layout>
        <Banner />
        <Properties properties={properties || []} />
      </Layout>
    </div>
  );
};

export default PropertiesPage;
