import { Box, Modal } from '@mui/material';
import { Link } from 'gatsby';
import React from 'react';
import Countdown from 'react-countdown';
import {
  FaClock,
  FaCross,
  FaMapMarkerAlt,
  FaPlus,
  FaStopCircle,
  FaWindowClose,
} from 'react-icons/fa';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import CountdownDisplay from '../common/CountdownDisplay';
import home from '/public/images/home.png';
import Log from '/public/images/property/los.png';
import propertyPlaceholder from '/public/images/property/property-placeholder.png';
import Slider from 'react-slick';

const PropertyCard = ({ singleData, colActiv }) => {
  const redirectUrl = process.env.GATSBY_REDIRECT_BASE_URL;
  const [opendetails, setOpenDetails] = React.useState(false);
  const handleOpenDetails = () => setOpenDetails(true);
  const handleCloseDetails = () => setOpenDetails(false);
  const [openImages, setOpenImages] = React.useState(false);
  const handleImagesOpenDetails = () => setOpenImages(true);
  const handleImagesCloseDetails = () => setOpenImages(false);

  let image =
    singleData.image_urls != null ? singleData.image_urls[0].url : Log;

  const {
    city = singleData.title,
    totalInvest = '',
    investAmount = singleData.price,
    investPer = 60,
    goal = 'abcd',
    annualReturn = 'abcd',
    share_price,
    max_term = 'abcd',
    type = 'abcd',
    security = 'abcd',
    rate_of_dividend,
    title = singleData.title || singleData.name,
    location = singleData.nearest_town +
      ', ' +
      singleData.state +
      ', ' +
      singleData.country,
    price,
    area_size,

    img = Array.isArray(singleData?.image_urls) &&
    singleData?.image_urls.length > 0
      ? singleData?.image_urls[0].url
      : singleData?.image_url,
    investLink = 'abcd',
    detailsLink = singleData.name ? singleData.product_id : singleData.slug,
  } = singleData;
  const settings = {
    dots: true, // Show dots navigation
    infinite: true, // Enable infinite scrolling
    speed: 500, // Transition speed in milliseconds
    slidesToShow: 1, // Number of slides to show at once
    slidesToScroll: 1, // Number of slides to scroll at once
    autoplay: true, // Enable autoplay
    autoplaySpeed: 3000, // Autoplay speed in milliseconds
    arrows: true, // Show previous and next arrows
  };
  return (
    <div
      className={`property__grid__area__wrapper__inner ${
        colActiv ? ' ' : 'col-xl-4 col-md-6'
      }`}
    >
      <div>
        <Modal
          open={opendetails}
          onClose={handleCloseDetails}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <div className='position-relative d-flex flex-column justify-content-center min-vh-100 overflow-hidden'>
            <div className='container p-6 mx-auto bg-white rounded-md shadow-lg'>
              <Box
                sx={{
                  cursor: 'pointer',
                  marginTop: '15px',
                }}
                onClick={() => handleCloseDetails()}
              >
                <FaWindowClose />
              </Box>
              <h3
                className='text-3xl font-bold text-center text-stone-900 capitalize'
                onClick={handleOpenDetails}
              >
                {singleData.title}
              </h3>
              <h5 className='text-l text-center text-stone-800 capitalize'>
                {singleData.subtitle}
              </h5>

              <div className='property__grid__single m-2 p-6 text-center'>
                <Box
                  sx={{
                    display: 'flex',
                    fontWeight: '500',
                    fontSize: '1rem',
                    pt: 1,
                  }}
                  className=' d-flex align-items-center gap-1'
                >
                  <FaMapMarkerAlt />{' '}
                  {`${singleData.state}, ${singleData.country}`}
                </Box>
                <div className='property__grid__single__inner'>
                  <Box sx={{ display: 'flex' }}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: singleData.description,
                      }}
                      className={'itemStyles p-1'}
                    />
                  </Box>

                  <div className='item__info'>
                    <div className='item__info__single'>
                      <>
                        <p>Price</p>
                        <h6>{`${singleData.price} ₹`}</h6>
                      </>
                    </div>

                    <div className='item__info__single'>
                      <>
                        <p>Area Size</p>
                        <h6>{`${singleData.area_size}` || '-'}</h6>
                      </>
                    </div>
                  </div>
                </div>
                <div className='item__cta__group'>
                  <Link
                    to={redirectUrl}
                    className='button button--secondary button--effect'
                  >
                    Invest Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
      <div>
        <Modal
          open={openImages}
          onClose={handleImagesCloseDetails}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <div className='position-relative d-flex flex-column justify-content-center min-vh-100 overflow-hidden'>
            <div className='container p-6 mx-auto bg-white rounded-md shadow-lg'>
              <Box
                sx={{
                  cursor: 'pointer',
                  marginTop: '15px',
                }}
                onClick={() => handleImagesCloseDetails()}
              >
                <FaWindowClose />
              </Box>
              <h3
                className='text-3xl font-bold text-center text-stone-900 capitalize'
                onClick={handleImagesOpenDetails}
              >
                {singleData.title}
              </h3>
              <h5 className='text-l text-center text-stone-800 capitalize'>
                {singleData.subtitle}
              </h5>

              <div className='property__grid__single m-2 p-6 text-center'>
                <Slider {...settings}>
                  {singleData?.image_urls !== null ? (
                    singleData?.image_urls.map((image, index) => (
                      <div
                        key={index}
                        className='img__effect row d-flex align-items-center'
                      >
                        <img
                          src={image.url}
                          alt={`Image ${index + 1}`}
                          width={'500px'}
                          height={'500px'}
                        />
                      </div>
                    ))
                  ) : (
                    <Box
                      sx={{
                        width: '500px',
                        height: '500px',
                        backgroundImage: `url(${propertyPlaceholder})`,

                        backgroundSize: 'cover',
                      }}
                    />
                  )}
                </Slider>
              </div>
            </div>
          </div>
        </Modal>
      </div>
      <div
        className={`property__list__wrapper ${
          colActiv ? ' ' : 'property__grid'
        }`}
      >
        <div className='row d-flex align-items-center'>
          <div
            className={`property__grid__area__wrapper__inner__two ${
              colActiv ? 'col-xl-5' : ' '
            }`}
          >
            <div className='property__item__image column__space--secondary'>
              <div
                className='img__effect'
                onClick={handleImagesOpenDetails}
                style={{ cursor: 'pointer' }}
              >
                <Box
                  sx={{
                    width: '100%',
                    height: '14rem',
                    backgroundImage: `url(${
                      img || propertyPlaceholder
                    }),url(${propertyPlaceholder})`,
                    backgroundSize: 'cover',
                    borderRadius: '0.5rem',
                  }}
                />
              </div>
            </div>
          </div>
          <div
            className={`property__grid__area__wrapper__inner__three ${
              colActiv ? 'col-xl-7' : ''
            }`}
          >
            <div className='property__item__content'>
              <div className='item__head'>
                <div className='item__head__left'>
                  <h4 onClick={handleOpenDetails} style={{ cursor: 'pointer' }}>
                    {title.slice(0, 16)}
                    {title.length > 20 && '...'}
                  </h4>
                  <p hidden={!singleData.state}>
                    <i>
                      <FaMapMarkerAlt />
                    </i>{' '}
                    {location}
                  </p>
                </div>
                <div className='item__head__right'>
                  <div className='countdown__wrapper'>
                    <p className='secondary'>
                      <i>
                        <FaClock />
                      </i>{' '}
                      Left to invest
                    </p>
                  </div>
                </div>
              </div>
              {/* <div className='progress__type progress__type--two'>
                <div className='progress'>
                  <div
                    className='progress-bar'
                    role='progressbar'
                    aria-valuenow='25'
                    aria-valuemin='0'
                    aria-valuemax='100'
                  ></div>
                </div>
                <div className='project__info'>
                  <p className='project__has'>
                    <span className='project__has__investors'>
                      {totalInvest} Investors
                    </span>{' '}
                    |{' '}
                    <span className='project__has__investors__amount'>
                      $ {investAmount}
                    </span>{' '}
                    <span className='project__has__investors__percent'>
                      ({investPer}%)
                    </span>
                  </p>
                  <p className='project__goal'>${goal} Goal</p>
                </div>
              </div> */}
              <div className='item__info'>
                <div className='item__info__single'>
                  {share_price ? (
                    <>
                      <p>Share Price</p>
                      <h6>{share_price + ' ₹'}</h6>
                    </>
                  ) : (
                    <>
                      <p>Price</p>
                      <h6>{price + ' ₹'}</h6>
                    </>
                  )}
                </div>
                <div className='item__info__single'>
                  <p>Maximum Term</p>
                  <h6>{max_term} Months</h6>
                </div>
                <div className='item__info__single'>
                  {rate_of_dividend ? (
                    <>
                      <p>Rate of Divident</p>
                      <h6>{rate_of_dividend || '-'}</h6>
                    </>
                  ) : (
                    <>
                      <p>Area Size</p>
                      <h6>{area_size || '-'}</h6>
                    </>
                  )}
                </div>
                <div className='item__info__single'>
                  <p>Distribution</p>
                  <h6>{type}</h6>
                </div>
              </div>
              <div className='item__footer'>
                <div className='item__security'>
                  <div className='icon__box'>
                    <img src={home} alt='Security' />
                  </div>
                  <div className='item__security__content'>
                    <p className='secondary'>Security</p>
                    <h6>{security} Mortgage</h6>
                  </div>
                </div>
                <div className='item__cta__group'>
                  <Link to={redirectUrl} className='button button--effect'>
                    Invest Now
                  </Link>
                  <Link
                    to={redirectUrl}
                    className='button button--secondary button--effect'
                  >
                    Details
                  </Link>
                </div>
              </div>
              <div className='invest__cta__wrapper'>
                <div className='countdown__wrapper'>
                  {/* <p className='secondary'>
                    <i>
                      <FaClock />
                    </i>{' '}
                    Left to invest
                  </p> */}
                </div>
                <div className='invest__cta'>
                  <Link to={redirectUrl} className='button button--effect'>
                    Invest Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertyCard;
